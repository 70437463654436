import React from "react"
import RacepageFS from "../components/racepageFS"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Amstel Gold Race",
      edition: 2021,
      what: "Startlist",
      updated: "2021-04-17 16:38:08",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21797: {
        teamId: 21797,
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
        teamNationName: "Norway",
      },
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21799: {
        teamId: 21799,
        teamUciCode: "APT",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
        teamNationName: "Kazakhstan",
      },
      21800: {
        teamId: 21800,
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
        teamNationName: "Bahrain",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21811: {
        teamId: 21811,
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21821: {
        teamId: 21821,
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
        teamNationName: "Australia",
      },
      21822: {
        teamId: 21822,
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21829: {
        teamId: 21829,
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
        teamNationName: "Netherlands",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21852: {
        teamId: 21852,
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21858: {
        teamId: 21858,
        teamUciCode: "GAZ",
        teamName: "Gazprom-RusVelo",
        teamNationCode: "RUS",
        teamNationName: "Russia",
      },
      21863: {
        teamId: 21863,
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21864: {
        teamId: 21864,
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
    },
    riders: {
      1: {
        id: 12474,
        startno: 1,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      2: {
        id: 6227,
        startno: 2,
        firstName: "Mattia",
        lastName: "Cattaneo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-10-25",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      3: {
        id: 27111,
        startno: 3,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      4: {
        id: 70034,
        startno: 4,
        firstName: "Mauri",
        lastName: "Vansevenant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-06-01",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      5: {
        id: 54278,
        startno: 5,
        firstName: "Ian",
        lastName: "Garrison",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-14",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      6: {
        id: 12481,
        startno: 6,
        firstName: "Florian",
        lastName: "S\u00e9n\u00e9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      7: {
        id: 3165,
        startno: 7,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      11: {
        id: 18655,
        startno: 11,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      12: {
        id: 16988,
        startno: 12,
        firstName: "Sam",
        lastName: "Oomen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      13: {
        id: 18258,
        startno: 13,
        firstName: "Wout",
        lastName: "Van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      14: {
        id: 16986,
        startno: 14,
        firstName: "Lennard",
        lastName: "Hofstede",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-12-29",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      15: {
        id: 369,
        startno: 15,
        firstName: "Paul",
        lastName: "Martens",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1983-10-26",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16: {
        id: 41,
        startno: 16,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      17: {
        id: 38195,
        startno: 17,
        firstName: "Jonas",
        lastName: "Vingegaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-12-10",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      21: {
        id: 2961,
        startno: 21,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      22: {
        id: 7724,
        startno: 22,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      23: {
        id: 65025,
        startno: 23,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      24: {
        id: 23097,
        startno: 24,
        firstName: "Richard",
        lastName: "Carapaz",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-05-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      25: {
        id: 926,
        startno: 25,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-04-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      26: {
        id: 27283,
        startno: 26,
        firstName: "Edward",
        lastName: "Dunbar",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-09-01",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      27: {
        id: 2313,
        startno: 27,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      31: {
        id: 581,
        startno: 31,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      32: {
        id: 5059,
        startno: 32,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      33: {
        id: 37353,
        startno: 33,
        firstName: "Nicola",
        lastName: "Conci",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-01-05",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      34: {
        id: 10382,
        startno: 34,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      35: {
        id: 17182,
        startno: 35,
        firstName: "Julien",
        lastName: "Bernard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      36: {
        id: 3930,
        startno: 36,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37: {
        id: 7572,
        startno: 37,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-12-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      41: {
        id: 150,
        startno: 41,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      42: {
        id: 6413,
        startno: 42,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      43: {
        id: 27301,
        startno: 43,
        firstName: "Aur\u00e9lien",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-02-27",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      44: {
        id: 327,
        startno: 44,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      45: {
        id: 37427,
        startno: 45,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      46: {
        id: 37717,
        startno: 46,
        firstName: "Dorian",
        lastName: "Godon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-25",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      47: {
        id: 21921,
        startno: 47,
        firstName: "Cl\u00e9ment",
        lastName: "Venturini",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      51: {
        id: 16643,
        startno: 51,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      52: {
        id: 2165,
        startno: 52,
        firstName: "Wilco",
        lastName: "Kelderman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-03-25",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      53: {
        id: 45602,
        startno: 53,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      54: {
        id: 3224,
        startno: 54,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-03",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      55: {
        id: 37439,
        startno: 55,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1997-02-18",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      56: {
        id: 6245,
        startno: 56,
        firstName: "Patrick",
        lastName: "Konrad",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-10-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      57: {
        id: 92,
        startno: 57,
        firstName: "Marcus",
        lastName: "Burghardt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1983-06-30",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      61: {
        id: 568,
        startno: 61,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      62: {
        id: 9894,
        startno: 62,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      63: {
        id: 2503,
        startno: 63,
        firstName: "Sander",
        lastName: "Armee",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-12-10",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      64: {
        id: 52660,
        startno: 64,
        firstName: "Sean",
        lastName: "Bennett",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-03-31",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      65: {
        id: 5592,
        startno: 65,
        firstName: "Sergio",
        lastName: "Henao",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-12-10",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      66: {
        id: 4001,
        startno: 66,
        firstName: "Bert-Jan",
        lastName: "Lindeman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-06-16",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      67: {
        id: 27139,
        startno: 67,
        firstName: "Robert",
        lastName: "Power",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-11",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      71: {
        id: 16569,
        startno: 71,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      72: {
        id: 706,
        startno: 72,
        firstName: "Daryl",
        lastName: "Impey",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1984-12-06",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      73: {
        id: 16787,
        startno: 73,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      74: {
        id: 32892,
        startno: 74,
        firstName: "Hugo",
        lastName: "Hofstetter",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-02-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      75: {
        id: 1937,
        startno: 75,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-09-25",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      76: {
        id: 16660,
        startno: 76,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      77: {
        id: 2294,
        startno: 77,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      81: {
        id: 37378,
        startno: 81,
        firstName: "David",
        lastName: "Gaudu",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-10",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      82: {
        id: 6222,
        startno: 82,
        firstName: "Rudy",
        lastName: "Molard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-17",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      83: {
        id: 12493,
        startno: 83,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      84: {
        id: 352,
        startno: 84,
        firstName: "Matthieu",
        lastName: "Ladagnous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1984-12-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      85: {
        id: 37379,
        startno: 85,
        firstName: "Valentin",
        lastName: "Madouas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      86: {
        id: 164,
        startno: 86,
        firstName: "William",
        lastName: "Bonnet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1982-06-25",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      87: {
        id: 46941,
        startno: 87,
        firstName: "Romain",
        lastName: "Seigle",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-10-11",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      91: {
        id: 6228,
        startno: 91,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      92: {
        id: 51348,
        startno: 92,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-14",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      93: {
        id: 53489,
        startno: 93,
        firstName: "Brent",
        lastName: "Van Moer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-12",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      94: {
        id: 2281,
        startno: 94,
        firstName: "Tomasz",
        lastName: "Marczynski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-03-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      95: {
        id: 46003,
        startno: 95,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      96: {
        id: 1717,
        startno: 96,
        firstName: "Tosh",
        lastName: "Van der Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-11-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      97: {
        id: 63035,
        startno: 97,
        firstName: "S\u00e9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      101: {
        id: 929,
        startno: 101,
        firstName: "Wout",
        lastName: "Poels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-10-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      102: {
        id: 2338,
        startno: 102,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      103: {
        id: 120,
        startno: 103,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      104: {
        id: 3050,
        startno: 104,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      105: {
        id: 16687,
        startno: 105,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      106: {
        id: 37376,
        startno: 106,
        firstName: "Stephen",
        lastName: "Williams",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-09",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      107: {
        id: 8068,
        startno: 107,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      111: {
        id: 2211,
        startno: 111,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      112: {
        id: 1715,
        startno: 112,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      113: {
        id: 3213,
        startno: 113,
        firstName: "Esteban",
        lastName: "Chaves",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-01-17",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      114: {
        id: 9234,
        startno: 114,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      115: {
        id: 2212,
        startno: 115,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      116: {
        id: 10975,
        startno: 116,
        firstName: "Dion",
        lastName: "Smith",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-03-03",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      117: {
        id: 44537,
        startno: 117,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      121: {
        id: 6309,
        startno: 121,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      122: {
        id: 3355,
        startno: 122,
        firstName: "Lawson",
        lastName: "Craddock",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-02-20",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      123: {
        id: 58639,
        startno: 123,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      124: {
        id: 7695,
        startno: 124,
        firstName: "Magnus",
        lastName: "Cort",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-01-16",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      125: {
        id: 7566,
        startno: 125,
        firstName: "Alex",
        lastName: "Howes",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1988-01-01",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      126: {
        id: 51252,
        startno: 126,
        firstName: "Simon",
        lastName: "Carr",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-08-29",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      127: {
        id: 108732,
        startno: 127,
        firstName: "Daniel",
        lastName: "Arroyave",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-06-19",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      131: {
        id: 602,
        startno: 131,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      132: {
        id: 16581,
        startno: 132,
        firstName: "Aim\u00e9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      133: {
        id: 31066,
        startno: 133,
        firstName: "Quinten",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-29",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      134: {
        id: 7919,
        startno: 134,
        firstName: "Maurits",
        lastName: "Lammertink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-08-31",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      135: {
        id: 27275,
        startno: 135,
        firstName: "Lorenzo",
        lastName: "Rota",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-05-23",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      136: {
        id: 3095,
        startno: 136,
        firstName: "Kevin",
        lastName: "Van Melsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-04-01",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      137: {
        id: 9073,
        startno: 137,
        firstName: "Loic",
        lastName: "Vliegen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-12-20",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      141: {
        id: 264,
        startno: 141,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      142: {
        id: 6230,
        startno: 142,
        firstName: "Alexey",
        lastName: "Lutsenko",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1992-09-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      143: {
        id: 27307,
        startno: 143,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      144: {
        id: 7640,
        startno: 144,
        firstName: "Omar",
        lastName: "Fraile",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-17",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      145: {
        id: 3199,
        startno: 145,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      146: {
        id: 48461,
        startno: 146,
        firstName: "Samuele",
        lastName: "Battistella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-11-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      147: {
        id: 44341,
        startno: 147,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      151: {
        id: 27308,
        startno: 151,
        firstName: "Fernando",
        lastName: "Barcel\u00f3",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-01-06",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      152: {
        id: 3044,
        startno: 152,
        firstName: "Nicolas",
        lastName: "Edet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-12-03",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      153: {
        id: 10807,
        startno: 153,
        firstName: "Ruben",
        lastName: "Fernandez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-03-01",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      154: {
        id: 168,
        startno: 154,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      155: {
        id: 47307,
        startno: 155,
        firstName: "Emmanuel",
        lastName: "Morin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-03-13",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      156: {
        id: 10990,
        startno: 156,
        firstName: "Guillaume",
        lastName: "Martin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-09",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      157: {
        id: 16566,
        startno: 157,
        firstName: "Anthony",
        lastName: "Perez",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-22",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      161: {
        id: 38,
        startno: 161,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      162: {
        id: 25695,
        startno: 162,
        firstName: "Jorge",
        lastName: "Arcas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-07-08",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      163: {
        id: 38169,
        startno: 163,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      164: {
        id: 6409,
        startno: 164,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      165: {
        id: 11828,
        startno: 165,
        firstName: "Ivan",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      166: {
        id: 25690,
        startno: 166,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      167: {
        id: 7781,
        startno: 167,
        firstName: "Carlos",
        lastName: "Verona",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-11-04",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      171: {
        id: 16786,
        startno: 171,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      172: {
        id: 9322,
        startno: 172,
        firstName: "Chad",
        lastName: "Haga",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1988-08-26",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      173: {
        id: 16817,
        startno: 173,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      174: {
        id: 69145,
        startno: 174,
        firstName: "Mark",
        lastName: "Donovan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-04-03",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      175: {
        id: 16610,
        startno: 175,
        firstName: "S\u00f8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      176: {
        id: 7729,
        startno: 176,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      177: {
        id: 82912,
        startno: 177,
        firstName: "Kevin",
        lastName: "Vermaerke",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2000-10-16",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      181: {
        id: 3933,
        startno: 181,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      182: {
        id: 114,
        startno: 182,
        firstName: "Rui",
        lastName: "Costa",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1986-10-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      183: {
        id: 46163,
        startno: 183,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      184: {
        id: 37777,
        startno: 184,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      185: {
        id: 16998,
        startno: 185,
        firstName: "Ryan",
        lastName: "Gibbons",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1994-08-13",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      186: {
        id: 6538,
        startno: 186,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      187: {
        id: 66265,
        startno: 187,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      191: {
        id: 4779,
        startno: 191,
        firstName: "Oscar",
        lastName: "Riesebeek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-12-23",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      192: {
        id: 22679,
        startno: 192,
        firstName: "Floris",
        lastName: "De Tier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-20",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      193: {
        id: 12482,
        startno: 193,
        firstName: "Xandro",
        lastName: "Meurisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-31",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      194: {
        id: 2156,
        startno: 194,
        firstName: "Philip",
        lastName: "Walsleben",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1987-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      195: {
        id: 10862,
        startno: 195,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      196: {
        id: 94645,
        startno: 196,
        firstName: "Ben",
        lastName: "Tulett",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-08-26",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      197: {
        id: 6229,
        startno: 197,
        firstName: "Jimmy",
        lastName: "Janssens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      201: {
        id: 6104,
        startno: 201,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      202: {
        id: 20104,
        startno: 202,
        firstName: "Benjamin",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-04",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      203: {
        id: 7771,
        startno: 203,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      204: {
        id: 19254,
        startno: 204,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      205: {
        id: 13898,
        startno: 205,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-11-29",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      206: {
        id: 3133,
        startno: 206,
        firstName: "Laurent",
        lastName: "Pichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-07-19",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      207: {
        id: 49662,
        startno: 207,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      211: {
        id: 45354,
        startno: 211,
        firstName: "Ruben",
        lastName: "Apers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-25",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      212: {
        id: 53490,
        startno: 212,
        firstName: "Rune",
        lastName: "Herregodts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-27",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      213: {
        id: 39186,
        startno: 213,
        firstName: "Julian",
        lastName: "Mertens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-10-06",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      214: {
        id: 10952,
        startno: 214,
        firstName: "Thomas",
        lastName: "Sprengers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-02-05",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      215: {
        id: 45356,
        startno: 215,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      216: {
        id: 37782,
        startno: 216,
        firstName: "Aaron",
        lastName: "Verwilst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-05-02",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      217: {
        id: 37434,
        startno: 217,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-04",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      221: {
        id: 9072,
        startno: 221,
        firstName: "Boris",
        lastName: "Vall\u00e9e",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-03",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      222: {
        id: 20822,
        startno: 222,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      223: {
        id: 37996,
        startno: 223,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-10-31",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      224: {
        id: 39167,
        startno: 224,
        firstName: "Kenny",
        lastName: "Molly",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-12-24",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      225: {
        id: 50288,
        startno: 225,
        firstName: "Luc",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1998-07-07",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      226: {
        id: 18574,
        startno: 226,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-05-22",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      227: {
        id: 27086,
        startno: 227,
        firstName: "Tom",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1996-03-04",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      231: {
        id: 36,
        startno: 231,
        firstName: "Roman",
        lastName: "Kreuziger",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1986-05-06",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      232: {
        id: 6526,
        startno: 232,
        firstName: "Sergey",
        lastName: "Chernetskiy",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1990-04-09",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      233: {
        id: 7573,
        startno: 233,
        firstName: "Marco",
        lastName: "Canola",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-12-26",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      234: {
        id: 16685,
        startno: 234,
        firstName: "Dmitriy",
        lastName: "Strakhov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1995-05-17",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      235: {
        id: 709,
        startno: 235,
        firstName: "Ivan",
        lastName: "Rovny",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1987-09-30",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      236: {
        id: 37278,
        startno: 236,
        firstName: "Petr",
        lastName: "Rikunov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1997-02-24",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      237: {
        id: 27277,
        startno: 237,
        firstName: "Simone",
        lastName: "Velasco",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-12-02",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
        stillInTheRace: "Y",
      },
      241: {
        id: 27229,
        startno: 241,
        firstName: "Jonas",
        lastName: "Abrahamsen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-09-20",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      242: {
        id: 49963,
        startno: 242,
        firstName: "Iver",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-03-15",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      243: {
        id: 16807,
        startno: 243,
        firstName: "Markus",
        lastName: "Hoelgaard",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-10-04",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      244: {
        id: 67824,
        startno: 244,
        firstName: "Jonas Iversby",
        lastName: "Hvideberg",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-02-09",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      245: {
        id: 27232,
        startno: 245,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-05-07",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      246: {
        id: 80276,
        startno: 246,
        firstName: "Martin",
        lastName: "Urianstad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-02-06",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      247: {
        id: 37304,
        startno: 247,
        firstName: "Syver",
        lastName: "W\u00e6rsted",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-08-08",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
    },
  }
  const race = "Amstel Gold Race"
  const raceID = 9

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
